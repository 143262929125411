.topImage {
  height: 250px;
  width: 100%;
  object-fit: cover;
}

.container {
  margin: 0 auto;
  margin-top: 20px;
  width: 60%;
  height: 100%;
  /*text-align: center;*/
  /*font-size: 20px;*/
}

.container img {
  width: 75px;
  height: 75px;
}

@media screen and (max-width: 999px) {
  .topImage {
    height: 100px;
  }

  .container {
    width: 80%;
    font-size: 16px;
  }

  .container img {
    width: 50px;
    height: 50px;
  }

}
:root {
	--booked-color: #fa4437;
	--option-color: #fa9c37;
	--free-color: #5bfa37;
	--disabled-color: #c2c2c2;
}

.topImage {
	height: 250px;
	width: 100%;
	object-fit: cover;
}

.container {
	margin: 0 auto;
	width: 60%;
	height: 100%;
}

.calendarContainer {
	display: flex;
	margin: 0 auto;
	width: 300px;
}

:global .react-calendar {
	margin: 20px auto 0 auto;
	width: 300px;
}

:global .react-calendar button {
	height: 25px;
	margin-top: 5px;
	margin-bottom: 5px;
	padding-top: 0;
	padding-bottom: 0;
}

:global .react-calendar__navigation {
	align-items: center;
}

:global .react-calendar__navigation__label {
	height: 30px !important;
}

:global .react-calendar__navigation__next-button {
	font-size: 25px;
	height: 30px !important;
}
:global .react-calendar__navigation__next2-button {
	font-size: 25px;
	height: 30px !important;
}
:global .react-calendar__navigation__prev-button {
	font-size: 25px;
	height: 30px !important;
}
:global .react-calendar__navigation__prev2-button {
	font-size: 25px;
	height: 30px !important;
}

.generalInfo {
	margin: 0 auto;
	width: 600px;
	margin-top: 20px;
	font-weight: 500;
	font-size: 15px;
}

.monthInfo {
	margin: 0 auto;
	width: 300px;
	margin-top: 20px;
	font-size: 15px;
	text-align: center;
}

.price {
	font-size: 16px;
	font-weight: bold;
}

.remarks {
	margin: 0 auto;
	width: 600px;
}

:global .react-calendar__month-view__days__day--weekend {
	color: black;
}

:global .react-calendar__tile {
	color: black;
}

.bookLink {
	background-color: rgb(25,12,245);
	text-align: center;
	padding: 5px;
}

.bookLink a {
	color: white;
	font-size: 18px;
	text-decoration: none;
	display: block;
	height: 100%;
	width: 100%;
}

.disabled {
	background: var(--disabled-color)  !important;
}

.bookedStart {
	background: linear-gradient(to bottom right, var(--free-color) 0%, var(--free-color) 50%, var(--booked-color) 50%, var(--booked-color) 100%) !important;
}

.optionStart {
	background: linear-gradient(to bottom right, var(--free-color) 0%, var(--free-color) 50%, var(--option-color) 50%, var(--option-color) 100%) !important;
}

.bookedEnd {
	background: linear-gradient(to bottom right, var(--booked-color) 0%, var(--booked-color) 50%, var(--free-color) 50%, var(--free-color) 100%) !important;
}

.optionEnd {
	background: linear-gradient(to bottom right, var(--option-color) 0%, var(--option-color) 50%, var(--free-color) 50%, var(--free-color) 100%) !important;
}

.bookedFull {
	background: var(--booked-color)  !important;
}

.optionFull {
	background: var(--option-color)  !important;
}

.optionEndBookedStart {
	background: linear-gradient(to bottom right, var(--option-color) 0%, var(--option-color) 50%, var(--booked-color) 50%, var(--booked-color) 100%) !important;
}

.bookedEndOptionStart {
	background: linear-gradient(to bottom right, var(--booked-color) 0%, var(--booked-color) 50%, var(--option-color) 50%, var(--option-color) 100%) !important;
}

.free {
	background: var(--free-color)  !important;
}

@media screen and (max-width: 999px) {
	.topImage {
		height: 100px;
	}

	.container {
		width: 90%;
	}

	.monthInfo {
		margin: 0 auto;
		text-align: center;
	}
	.calendarContainer {
		flex-direction: column;
		width: 300px;
	}
	.generalInfo {
		width: 300px;
	}
	.remarks {
		width: 300px;
	}
}



@font-face {
	font-family: 'MaiandraGD-Roman';
	src: url('./webfonts/27B0C0_0_0.eot');
	src: url('./webfonts/27B0C0_0_0.eot?#iefix') format('embedded-opentype'), url('./webfonts/27B0C0_0_0.woff') format('woff'), url('./webfonts/27B0C0_0_0.ttf') format('truetype');
}

.header {
	width: 100%
}

.headerContainer {
	display: flex;
	width: auto;
	padding: 0 20px;
	justify-content: space-between;
}

@media screen and (max-width: 999px) {
	.header {
		height: auto;
		padding-bottom: 10px;
	}
	.headerContainer {
		flex-direction: column;
	}
	.titleContainer {
		flex-direction: column;
	}
	.navbar {
		flex-direction: column;
	}
	.menuContainer {
		flex-direction: column;
		height: auto;
	}
	.subtitle {
		text-align: center
	}
	.menuCalendar {
		margin-left: 30%;
		margin-right: 30%;
	}
	.menu {
		padding: 4px 10px;
	}
}

@media screen and (min-width: 1000px) {
	.header {
		height: 75px;
	}
	.headerContainer {
		height: 100%;
		/*background-color: lime;*/
	}
	.menuContainer {
		height: 75px;
	}
	.menu {
		padding: 0 10px;
	}
	.menuCalendar {
		height: 75px;
	}
}

@media screen and (min-width: 1200px) {
	.headerContainer {
		width: 1200px;
		margin: 0 auto;
		position: relative;
		/*background-color: red;*/
	}

}

* {
	font-family: 'MaiandraGD-Roman';
}

.logo {
	height: 55px;
	padding-right: 10px;
}

.titleContainer {
	align-items: center;
	display: flex;
	padding: 9px;
	/*background-color: gray;*/
}

.titleContainer a {
	text-decoration: none;
	outline: none;
	color: #292929;
	/*background-color: orange;*/
}

.titleContainer h3 {
	font-weight: bold;
	margin: 0;
	font-size: 30px;
}

.title {
	white-space: pre;
}

.subtitle {
	display: block;
	font-size: 14px;
}

.navbar {
	display: flex;
	/*background-color: blueviolet;*/
}

.menuContainer {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 0;
	margin: 0;
	/*background-color: yellow;*/
}

.menu {
	color: bisque;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.menuCalendar {
	background: rgb(25,12,245);
	color: white !important;
}

.menuLink {
	/*  font-family: 'Roboto', sans-serif;*/
	text-decoration: none;
	outline: none;
	color: #292929;
	font-weight: 1000;
	font-size: 15px;
}

.menu a:hover  {
	color: rgb(25,12,245)
}

.menuLinkSelected {
	color: rgb(25,12,245) !important;
}

.language {
	display: flex;
	flex-direction: column;
	margin-left: 15px;
	margin-top: 2px;
	margin-right: 15px;
	/*background-color: aqua;*/
}

.language a {
	cursor: pointer;
	height: 24px;
	text-decoration: none;
	outline: none;
	/*color: #17bee8;*/
	font-weight: 500;
	font-size: 10px;
	color: black;
}

.language a:hover  {
	color: rgb(25,12,245)
}

.body {
	padding-bottom: 20px;
}

@media screen and (max-width: 999px) {
	.language {
		flex-direction: row;
		margin: 5px auto;
	}
	.language a {
		font-size: 12px;
	}
	.languageItem {
		margin: 0 15px 0 15px;
	}
	.logo {
		height: 50px;
		padding-right: 0;
	}
}

